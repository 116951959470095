import React from "react";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";

const CallUs = () => {
  const { t } = useTranslation("common");

  return (
    <div>
      <div className="mt-10 flex items-center flex-col justify-center">
        <a
          href={`tel:${t("call-us.call-now-action")}`}
          className="text-accent w-full font-display text-center text-lg font-semibold"
        >
          {t("call-us.call-now-btn")}
        </a>
        <div className="text-center text-xs">
          <p className="dark:text-white">{t("call-us.call-fees-may-apply")}</p>
        </div>
        <div className="flex gap-2 mt-3">
          <a href="viber://chat?number=359879149614">
            <Image
              width={30}
              height={30}
              src="/svgs/viber.svg"
              loading="lazy"
              alt="sofia airport taxi to city bansko transfer pamporovo transfer taxies"
            />
          </a>
          <a href="https://wa.me/+359879149614">
            <Image
              src="/svgs/whatsapp.svg"
              loading="lazy"
              alt="sofia airport taxi transfers rent a car bansko sofiq"
              width={30}
              height={30}
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CallUs;
